<template>
  <CEESAR-DropDown :label="userName" :contentAlign="contentAlign" icon="account_circle">
      <CEESAR-Button v-for="(option, index) in options" v-bind:key="index"
        :label="option.label" :icon="option.icon" v-on:click="option.do()" />
  </CEESAR-DropDown>
</template>

<script>
export default {
    name:"CEESAR-Keycloak-UserMenu",
    props:{
        contentAlign: {type: String, default: "Left"}
    },
    data(){
        return{
            userName: undefined,
            options: []
        };
    },
    mounted(){
        this.userName = this.$ceesar.router.getUserName();
        this.options = this.$ceesar.router.getUserMenuOptions();
    }
}
</script>